@import '../../styles/mixins';

$largest-size: 1440px;
$large-size: 1280px;
$medium-size: 1024px;
$small-size: 768px;
$small-mid-size: 667px;
$smallest-size: 480px;
$ultra-smallest-size: 375px;

$transition-slow: 450ms ease;
$transition-base: 300ms ease;

$font-size-base: 16;
$font-family-base: 'Open Sans', sans-serif;
$font-family-solid: 'Montserrat', sans-serif;
$font-family-italic: 'Libre Baskerville', sans-serif;

$color-blue: #006CA9;
$color-orange: $color-blue;
$color-orange-dark: $color-blue;
$color-orange-1: #FF6B58;
//$color-orange-dark: #c94a24;

$color-laguna: #00A3AF;
$color-laguna-light: #F2FAFB;
$color-laguna-dark: #007178;
//$color-laguna-dark: #00ADB5;

$color-grey-light: #adadad;
$color-grey: #676767;

$primary-1: #ae0032;
$primary-2: #455162;
$primary-3: #1f2f46;

$neutral-1: #0d1720;
$neutral-2: #636363;
$neutral-3: #dadada;
$neutral-4: #f2f2f2;
$neutral-5: #fcfbfa;

@import 'benefits-card.scss';

.s-benefits {
    overflow: hidden;

    &_bg-on {
        position: relative;
        overflow: visible;

        &::before {
            content: '';

            width: 100%;
            height: 100%;

            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            transform: skewY(-7deg);

            background-color: $color-laguna-light;
        }

        .s-benefits__container {
            padding-top: 100px;
            padding-bottom: 100px;

            position: relative;
            overflow: hidden;

            @media (min-width: $small-size) {
                padding-top: 150px;
                padding-bottom: 150px;
            }
        }
    }

    &_new-grid {
        .s-benefits__container {
            padding-top: 48px;
            padding-bottom: 72px;

            @media (min-width: 1024px) {
                padding-top: 72px;
            }
        }
    }

    &_paddings-min {
        .s-benefits__container {
            padding-bottom: 0;
            padding-top: 0;
        }
    }
    &_top-padding-min {
        .s-benefits__container {
            padding-top: 0;
        }
    }
}
.s-benefits__container {
    padding: {
        top: 120px;
        bottom: 40px;
    }

    @media (min-width: $small-size) {
        padding-bottom: 100px;
    }
    @media (min-width: $medium-size) {
        padding-top: 200px;
    }
}

.s-benefits__heading {
    margin-bottom: 20px;
}
.s-benefits__heading-italic {
    margin-left: 6px;
}

.s-benefits__gr-list {
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 30px;

    @media (min-width: $medium-size) {
        grid-template-columns: 1fr 1fr;
        align-items: start;
    }
}

.s-benefits__list-col {
    display: grid;
    grid-row-gap: 30px;

    &_fst {
        @media (min-width: $small-size) {
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 30px;
        }
        @media (min-width: $medium-size) {
            grid-template-columns: 1fr;
        }
    }
    & > * {
        &:last-child:nth-child(2n - 1) {
            @media (min-width: $small-size) {
                grid-column: 1 / 2 span;
            }
            @media (min-width: $medium-size) {
                grid-column: auto;
            }
        }
    }
    &_is-list-text {
        & > * {
            &:last-child:nth-child(2n) {
                @media (min-width: $small-size) {
                    grid-column: 1 / 2 span;
                }
                @media (min-width: $medium-size) {
                    grid-column: auto;
                }
            }
        }
    }
}
.s-benefits__list-text {
    @media (min-width: $small-size) {
        grid-column: 1 / 2 span;
    }
    @media (min-width: $medium-size) {
        grid-column: auto;
    }
}

.s-benefits__wr-mosaic {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;

    position: relative;

    @include aspect-ratio-w-pseudo(10, 11);

    @media (min-width: $smallest-size) {
        @include aspect-ratio-w-pseudo(20, 21);
    }
    @media (min-width: $small-size) {
        @include aspect-ratio-w-pseudo(1, 1);
    }
    @media (min-width: $medium-size) {
        margin: 0;
    }


}
.s-benefits__wr-img {
    width: 50%;

    @include img-wrap;

    position: absolute;

    &:nth-child(1) {
        top: 0;
        left: 0;

        @include aspect-ratio-w-pseudo(1, 1);

        @media (min-width: $small-size) {
            width: 45%;
        }
    }
    &:nth-child(2) {
        top: 24px;
        right: 0;
        z-index: 1;

        @include aspect-ratio-w-pseudo(1, 1.5);
    }
    &:nth-child(3) {
        bottom: 0;
        left: 44px;

        @include aspect-ratio-w-pseudo(1, 1);
        
        @media (min-width: $medium-size) {
            left: 100px;
        }
    }
}

.benefits__grid {
    display: grid;
    grid-row-gap: 40px;

    @media (min-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 32px;
    }
    @media (min-width: 1280px) {
        grid-template-columns: 5fr 4fr;
    }
}
.benefits__column {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .cd-benefit,
    .cd-benefit__text {
        max-width: 700px;
    }
}
.benefits__head-text {
    max-width: 530px;
    
    &:not(:first-child) {
        margin-top: 20px;
    }
}
.benefits__head {
    @media (min-width: 1024px) {
        margin-bottom: 20px;
    }
}
.benefits__column-row {
    &_mosaic {
        order: 1;

        @media (min-width: 1024px) {
            order: initial;
        }

        &:first-child {
            @media (min-width: 1024px) {
                margin-bottom: 100px;
            }
        }
        &:last-child {
            @media (min-width: 1024px) {
                margin-top: 100px;
            }
        }
    }
}